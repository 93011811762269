import React, { Fragment } from 'react';
import Header from '@components/Header';
import Footer from '@components/Footer';
import Page from '@components/Page';
import { css } from '@emotion/react';
import { getPageElements } from '@lib/utils';
import { graphql } from 'gatsby';

export default function GenericTemplate({
  data: { page },
  pageContext: { language, refs, layouts, configuration }
}) {
  const { body } = getPageElements(page);
  return (
    <Page>
      <Header
        dark
        language={language}
        refs={refs}
        layouts={layouts}
      />
      <div
        className="py-5"
        css={css`
          h2,
          h3,
          h4 {
            margin-top: 1em;
          }
        `}
        dangerouslySetInnerHTML={{ __html: body }}
      >
      </div>
      <Footer
        configuration={configuration}
        language={language}
        layouts={layouts}
      />
    </Page>
  );
}

export const query = graphql`
  query($id: String) {
    page: wpWebsite(id: { eq: $id }) {
      ...Page
    }
  }
`;